.sizenav{
    font-size: 16px;
}

.sizebt{
    height: 35px;
    font-size: 12px;
    
}

body, html {
  font-family: "Roboto",Arial,Helvetica;
}
.fncol{
    color: black;
    font-size: 14px;

    
}

.fncoll{
  color: black;

  
}

.nextb{
    background-color: rgb(235, 137, 137);
}

.sizeheader{
    font-size: 60px;
    font-weight: bold;
}

.sizebtn{
    font-size: 20px;
}

.sizecardheader{
    font-size: 30px;
    font-weight: bold;
}


html,body {
    margin: 0;
    padding: 0;
    
  }

.footer{
    background-color: #070617;
}

.why-us h2 {
    position: relative;
    margin-bottom: 35px;
}
.why-us h2::after {
    content: "";
    width: 120px;
    height: 3px;
    display: inline-block;
    background: #FFC107;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -20px;
    margin: 0 auto;
}

.why-us .box {
    padding: 50px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
    transition: 0.5s;
    position: relative;
    background-color: #333;
    max-height: 230px;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 10px;
}
.why-us .box:hover {
	padding: 30px 30px 70px 30px;
	box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.20);
	background-color: rgba(0, 0, 0, 0.3);
}
.why-us .box img {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: -1;
	opacity: 0;
	transition:all ease 1s; 
}
.why-us .box:hover img {
	opacity: 1;
}
.why-us .box span {
    display: block;
    font-size: 56px;
    font-weight: 700;
    color: #6b6060;
    position: absolute;
    right: 10px;
    top: 0px;
    line-height: normal;
}
.why-us .box h4 a {
	font-size: 24px;
	font-weight: 600;
	padding: 0;
	margin: 20px 0;
	color: #dadada;
	text-decoration: none;
}
.why-us .box p {
	color: #aaaaaa;
	font-size: 15px;
	margin: 0;
	padding: 0;
}
.why-us .box:hover span,
.why-us .box:hover h4 a,
.why-us .box:hover p {
  	color: #fff;
}



footer {
  background: #4e4e4e;
    color: #fff;
    font-size: 14px;
}
footer .footer-top {
   
    background: linear-gradient(to right, #e74a4a, #e44343);
    padding: 50px 0px 20px;
}
footer .address p {
    font-size: 16px;
    color: #ddd;
}
footer .social-links a {
    font-size: 17px;
    display: inline-block;
    background: #28251f;
    color: #fff;
    line-height: 1;
    padding: 9px 0;
    margin-right: 5px;
    border-radius: 100%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.4s;
}
footer .social-links a:hover {
    background: #8bc34a;
}
footer h4 {
    font-size: 18px;
    color: #cddc39;
    padding-bottom: 12px;
}
footer .footer-menus {
    margin-bottom: 30px;
}
footer .footer-menus ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
footer .footer-menus ul li {
    margin-bottom: 10px;
}
footer .footer-menus ul i {
    color: #ffffff;
    font-size: 12px;
    margin-right: 3px;
}
footer .footer-menus ul a {
    color: #fff;
    text-decoration: none;
}
footer .footer-menus ul a:hover {
    color: #cddc39;
}
footer .newsletter form {
    margin-top: 30px;
    background: #3a3a3a;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    border: 1px solid #3a3a3a;
}
footer .newsletter form input[type="email"] {
    border: 0;
    padding: 5px;
    background: #3a3a3a;
    color: #fff;
}
footer .newsletter form input[type="email"]:focus {
    outline: none;
}
footer .newsletter form input[type="submit"] {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 0;
    font-size: 16px;
    background: #cddc39;
    color: #000;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}
footer .newsletter form input[type="submit"]:hover {
    background: #d5e440;
}
.footer-bottom {
    padding: 20px 0px;
}
footer .footer-bottom a {
    color: #cddc39;
    text-decoration: none;
}

.nav-item{
    margin-left: 20px;
}




            

.profile-card {
    transition: 0.5s;
    width: 300px;

}
.profile-card:hover {
    transform: scale(1.3);
    transition: 0.5s;
}
.profile-photo {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
}
.social-links ul {
    padding: 0px;
    margin: 0px;
}
.social-links ul li {
    list-style: none;
    display: inline-block;
    margin: 2px 0px;
    font-size: 20px;            
}
.social-links ul li a {
    color: #333;
    padding: 2px 6px;
    border-radius: 5px;
    transition: 0.5s;
}
.social-links ul li:hover a {
    background-color: #333333;
    color: #fff;
    transition: 0.5s;
}



.arrow{
    width: 60px;
    height: 60px;
}



.d-block{
    height: 450px;
}
  
.c{
    font-weight: bold;
    font-size: 80px;
    border: blue;
}

.d{
    height: 150px;
    width: 100%;
}




.fn{
    height: 100px;
    padding: 10px;
    width: 100%;
}


.ma{
    margin-top: 100px;
    margin-bottom: 200px;
}

.maa{
    margin-top: 100px;
    margin-bottom: 100px;
}







  a,
  input,
  select,
  textarea {
    outline: none;
    margin: 0;
    padding: 0;
  }
  a:hover,
  focus {
    text-decoration: none;
    outline: none;
    border: none;
  }


  .mt-30,
  .mb-30 {
    margin: 30px 0;
  }
  .clear {
    clear: both;
  }
  .share-boxes p {
    margin: 15px 0 0;
    font-size: 15px;
    font-weight: bold;
  }
  .share-boxes {
    background: #f9f9f9;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 17px #ccc;
    padding: 20px 0;
    position: relative;
  }
  .share-boxes img.dotted-line {
    position: absolute;
    left: -167px;
    top: 5px;
    transform: rotate(-3deg);
  }
  .share-boxes img.dotted-line2 {
    position: absolute;
    right: -173px;
    top: 5px;
    transform: rotate(-4deg);
  }
  .refer-image img {
    width: 100%;
  }
  .refer-form ul li {
    float: left;
    list-style: none;
    width: 33.333%;
    text-align: center;
  }
  .refer-form ul li a {
    background: #9fb0f8;
    display: block;
    padding: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }
  .refer-form ul {
    margin: 0;
  }
  .refer-form ul li.facebook-color a {
    background: #9fb0f8;
  }
  .refer-form ul li.youtube-color a {
    background: #eb8c8c;
  }
  .refer-form ul li.twitter-color a {
    background: #9cd0fc;
  }
  .refer-form ul li.facebook-color a:hover {
    background: #4667f7;
    text-decoration: none;
  }
  .refer-form ul li.youtube-color a:hover {
    background: #dd2020;
    text-decoration: none;
  }
  .refer-form ul li.twitter-color a:hover {
    background: #40a7ff;
    text-decoration: none;
  }
  .refer-form-content {
    float: left;
    width: 100%;
    background: #f9f9f9;
    padding: 30px;
  }
  .refer-form-content h2 {
    color: #ffc3c9;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 25px;
    margin: 0 0 10px;
  }
  .refer-form-content P a {
    color: #ffc3c9;
    font-weight: 500;
  }
  .refer-form-content input {
    height: 50px;
    width: 100%;
    padding: 15px;
    border-radius: 1px;
    margin-bottom: 20px;
    box-shadow: 0 0 6px #ccc;
  }
  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #ffc3c9;
  }
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .container-checkbox input:checked ~ .checkmark {
    background-color: #ffc3c9;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  .container-checkbox .checkmark:after {
    left: 5px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .refer-form-content form button {
    background: #ffc3c9;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }
  .refer-form-content form button:hover {
    background: #000;
  }
  .refer-form-content input::placeholder {
    color: #c5c5c5;
    font-size: 14px;
  }
  .row.refer-form-sec {
    height: 450px;
    overflow: hidden;
    margin-top: 55px;
  }
  .referal-progress table td:nth-child(2) {
    text-align: right;
  }
  .referal-progress table td {
    border: 1px solid #cccc;
    padding: 15px 20px;
  }
  .row.refer-form-sec .col:first-child {
    padding-right: 0;
  }
  .row.refer-form-sec .col:last-child {
    padding-left: 0;
  }
  .referal-progress h2 {
    color: #ffc3c9;
    font-size: 22px;
    margin: 10px 0 15px;
  }
  .share-boxes:after {
    content: "";
    background: url("https://i.ibb.co/WHdS3G1/circle.png") no-repeat 0 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -65px;
    margin: 0 auto;
    z-index: 99999;
    height: 60px;
    width: 20px;
  }
  @media only screen and (max-width: 1100px) {
    .share-boxes img.dotted-line,
    .share-boxes img.dotted-line2 {
      display: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .share-boxes {
      margin: 0 0 52px;
    }
    .row.refer-form-sec {
      height: auto;
      overflow: hidden;
      margin-top: 55px;
      display: block;
    }
    .row.refer-form-sec .col:first-child {
      padding-right: 15px;
      margin: 0 0 30px;
    }
    .row.refer-form-sec .col:last-child {
      padding-left: 15px;
    }
  }

  

  .maaa{
    margin-top: 100px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 200px;
}

.navbar-toggler-icon{

  background-color: #6c757d;

}






    .our-skills {
    padding: 70px 0px 20px;
}
.our-skills .icon {
    padding: 10px 0px;
    background-color: #fff;
    margin: -45px auto 20px;
    border-radius: 10px;
    width: 90px;
}
.our-skills .icon i {
    color: #F44336;
    font-size: 40px;
    margin: 0 auto;
    display: table;
}
.our-skills .box-shadow {
    margin-bottom: 70px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #a5a5a5;
    padding: 15px;
    border-radius: 10px;
    float: right;
}
.our-skills .box-shadow:hover {
    box-shadow: 0px 0px 10px 0px #a5a5a5;
}
.our-skills p {
    font-size: 15px;
    line-height: 23px;
    color:#a09f9f;
    text-align: center;
}
.our-skills a {
    color: #6b6b6b;
    font-size: 16px;
    float: right;
    text-decoration: none;
    transition: 0.5s;
}
.our-skills h4 {
    text-transform: uppercase;
    text-align: center;
    color: #6b6b6b;
    font-weight: 500;
}


.modelback{
  width: 400px;
  height: 400px;
}

.bhg{
  width: 230px;
  height: 200px;
}

.bgu{
  background-color: rgba(241, 234, 234, 0.911);
}

.gn{
  width: 100px;
  height: 100px;
}


.clrt{
  background: linear-gradient(to right, #e3aaaa, #e84f4f);
}




.imggg{
  height: 50px;

}

.hei{
  height: 400px;
}



.bold-blue {
	font-weight: bold;
	color: #0277BD;
}


.fontt{
  font-weight: bold;
}

.bg-image {
  /* The image used */
  background-image: url("https://tpc.googlesyndication.com/daca_images/simgad/11450694618972305635");
  
}

.jum{
  background-color: #ccc;
}

.shadow{
  box-shadow: 0 .5rem 1rem #F44336 !important;
}


.mtr{
  margin-top: 100px;
}